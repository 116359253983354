import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function WrongIcon({ className }) {
    return (
        <svg
            className={classNames([
                {
                    [className]: className !== null,
                },
            ])}
            xmlns="http://www.w3.org/2000/svg"
            width="23.8"
            height="23.8"
            viewBox="0 0 23.8 23.8"
        >
            <path
                d="M11.9,0C5.33,0,0,5.33,0,11.9s5.33,11.9,11.9,11.9,11.9-5.33,11.9-11.9C23.82,5.35,18.53,.02,11.97,0c-.02,0-.05,0-.07,0Zm5.95,16.19l-1.67,1.67-4.28-4.28-4.29,4.28-1.67-1.67,4.29-4.28L5.95,7.62l1.67-1.67,4.28,4.28,4.28-4.28,1.67,1.67-4.28,4.28,4.29,4.29Z"
                fill="#f36d21"
            />
        </svg>
    );
}

WrongIcon.propTypes = propTypes;
WrongIcon.defaultProps = defaultProps;

export default WrongIcon;
