import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function WrongIcon({ className }) {
    return (
        <svg
            className={classNames([
                {
                    [className]: className !== null,
                },
            ])}
            xmlns="http://www.w3.org/2000/svg"
            width="23.8"
            height="23.8"
            viewBox="0 0 23.8 23.8"
        >
            <path
                d="M23.8,11.9c0,6.57-5.33,11.9-11.9,11.9S0,18.48,0,11.9,5.33,0,11.9,0C18.47,0,23.8,5.33,23.8,11.9h0Zm-13.28,6.3l8.83-8.83c.3-.3,.3-.79,0-1.09l-1.09-1.08c-.3-.3-.79-.3-1.09,0l-7.2,7.2-3.36-3.36c-.3-.3-.79-.3-1.09,0l-1.09,1.09c-.3,.3-.3,.79,0,1.09l4.99,4.99c.3,.3,.79,.3,1.09,0h0Z"
                fill="#56b948"
            />
        </svg>
    );
}

WrongIcon.propTypes = propTypes;
WrongIcon.defaultProps = defaultProps;

export default WrongIcon;
