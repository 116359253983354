import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Right from '../icons/Right';
import Wrong from '../icons/Wrong';

import styles from '../../styles/icons/pin.module.scss';

const propTypes = {
    step: PropTypes.number,
    type: PropTypes.oneOf(['right', 'wrong']),
    className: PropTypes.string,
    pinClassName: PropTypes.string,
};

const defaultProps = {
    step: null,
    type: null,
    className: null,
    pinClassName: null,
};

function Pin({ step, type, className, pinClassName }) {
    return (
        <span
            className={classNames([
                styles.container,
                {
                    [styles[`${type}Answer`]]: type !== null,
                    [className]: className !== null,
                },
            ])}
        >
            {step !== null && type === null ? <span className={styles.step}>{step}</span> : null}
            <svg
                className={classNames([
                    styles.pin,
                    {
                        [pinClassName]: pinClassName !== null,
                    },
                ])}
                width="34.68"
                height="45.91"
                viewBox="0 0 34.68 45.91"
            >
                <path
                    d="M15.61,44.51C2.87,26.03,.5,24.13,.5,17.34,.5,8.04,8.04,.5,17.34,.5s16.84,7.54,16.84,16.84c0,6.79-2.37,8.69-15.11,27.16-.66,.96-1.97,1.19-2.93,.53-.21-.14-.39-.32-.53-.53h0Z"
                    fill="#f8ddca"
                />
            </svg>
            <Wrong className={styles.wrong} />
            <Right className={styles.right} />
        </span>
    );
}

Pin.propTypes = propTypes;
Pin.defaultProps = defaultProps;

export default Pin;
