import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Right from '../icons/Right';
import Wrong from '../icons/Wrong';

import styles from '../../styles/partials/answer.module.scss';

const propTypes = {
    type: PropTypes.oneOf(['right', 'wrong']),
    className: PropTypes.string,
    children: PropTypes.node,
};

const defaultProps = {
    type: null,
    className: null,
    children: null,
};

function Answer({ type, className, children }) {
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                    [styles[`${type}Answer`]]: type !== null
                },
            ])}
        >
            <span>{children}</span>
            {type === 'right' ? <Right /> : null}
            {type === 'wrong' ? <Wrong /> : null}
        </div>
    );
}

Answer.propTypes = propTypes;
Answer.defaultProps = defaultProps;

export default Answer;
