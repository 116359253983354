import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Pin from './Pin';

import styles from '../../styles/partials/result-pins.module.scss';

const propTypes = {
    currentStep: PropTypes.number,
    hasFoundAnswer: PropTypes.bool,
    onClickPin: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    currentStep: 1,
    hasFoundAnswer: false,
    onClickPin: null,
    className: null,
};

const pins = ['first', 'second', 'third', 'fourth'];

function ResultPins({ currentStep, hasFoundAnswer, className, onClickPin }) {
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            {pins.map((p, i) => {
                const step = i + 1;
                const wrong = step < currentStep ? 'wrong' : null;
                const current = step === currentStep;
                const type = current && hasFoundAnswer ? 'right' : wrong;

                if (currentStep < step && hasFoundAnswer) return false;

                return (
                    <button
                        key={p}
                        className={classNames([
                            styles.btn,
                            {
                                [styles.isActive]: type !== null,
                                [styles.isCurrent]: current,
                            },
                        ])}
                        type="button"
                        disabled={(type !== 'wrong' && !current) || (step === 1 && current)}
                        onClick={onClickPin !== null ? () => onClickPin(step) : null}
                    >
                        <Pin className={styles.pin} step={step} type={type} />
                    </button>
                );
            })}
        </div>
    );
}

ResultPins.propTypes = propTypes;
ResultPins.defaultProps = defaultProps;

export default ResultPins;
