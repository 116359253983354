// import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { Routes, Route, useMatch, useNavigate, Navigate } from 'react-router-dom';

import { useGame, useResults } from './context/GameContext';
import { useRoutes } from './context/RoutesContext';
import MainLayout from './layouts/Main';
import FinishedPage from './pages/Finished';
import GamePage from './pages/Game';

import '../styles/main.scss';

const propTypes = {};

const defaultProps = {};

function App() {
    const { game, setGame, getRandomGame, getGameBySlug } = useGame();
    const { results, finished } = useResults();
    const navigate = useNavigate();
    const routes = useRoutes();
    const match = useMatch(`/:game`);
    const { params: { game: gameSlug = null } = {} } = match || {};

    const nextGameSlug = useMemo(() => {
        const nextGame = getRandomGame(gameSlug);
        return nextGame !== null ? nextGame.slug : null;
    }, [gameSlug]);

    useEffect(() => {
        const currentGame = getGameBySlug(gameSlug);
        if (finished) {
            navigate(routes.finished);
        } else if (currentGame !== null) {
            setGame(currentGame);
        } else {
            navigate(routes.home);
        }
    }, [nextGameSlug, finished, gameSlug, navigate]);

    return (
        <Routes>
            <Route exact path={routes.home} element={<Navigate to={`/${nextGameSlug}`} replace />} />
            <Route
                path={routes.game}
                element={
                    <MainLayout game={game}>
                        <GamePage game={game} />
                    </MainLayout>
                }
            />
            {finished ? (
                <Route
                    path={routes.finished}
                    element={
                        <MainLayout game={game}>
                            <FinishedPage results={results} />
                        </MainLayout>
                    }
                />
            ) : null}
            {nextGameSlug !== null ? (
                <Route path="*" element={<Navigate to={`/${nextGameSlug}`} replace />} />
            ) : null}
        </Routes>
    );
}

App.propTypes = propTypes;
App.defaultProps = defaultProps;

export default App;
