import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { games } from '../../lib/data';

import PageTitle from '../partials/PageTitle';

import styles from '../../styles/pages/finished.module.scss';

const propTypes = {
    results: PropTypes.shape({}),
};

const defaultProps = {
    results: null,
};

function FinishedPage({ results }) {
    const founds = Object.values(results).reduce((acc, score) => (score > 4 ? 0 : acc + 1), 0);
    const total = Object.values(results).length;
    const onClickOutlink = useCallback(() => {
        gtag('event', 'click_sepaq_link', {
            founds,
            total,
        });
    }, []);

    return (
        <div className={styles.container}>
            <PageTitle className={styles.pageTitle} title="Sépaq c’que tu penses | Bravo!" />
            <div className={styles.results}>
                <h2 className={styles.heading}>Résumé des résultats</h2>
                <p>On aurait pu te faire deviner pendant des heures!</p>
                <p>
                    La Sépaq, c’est <strong>24 parcs nationaux</strong> comprenant{' '}
                    <strong>6 995 km2</strong> de territoires protégés uniques aux quatre coins du
                    Québec.
                </p>
                <p>
                    <a
                        onClick={onClickOutlink}
                        href="https://www.sepaq.com/parcs-nationaux"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Découvre-les tous ici!
                    </a>
                </p>

                {Object.keys(results).map((resultId) => {
                    const park = games.find((g) => g.id === resultId);
                    const score = results[resultId];
                    const { title } = park || {};
                    const circles = [...Array(4).keys()]
                        .map((_, i) => {
                            const step = i + 1;
                            const wrongOrNeutral = score < step ? '⚪️' : '🟠';
                            const circle = score === step ? '🟢' : wrongOrNeutral;

                            return circle;
                        })
                        .join('');

                    return (
                        <div className={styles.result}>
                            <h3 className={styles.title}>{title}</h3>
                            <div className={styles.score}>🏕{circles}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

FinishedPage.propTypes = propTypes;
FinishedPage.defaultProps = defaultProps;

export default FinishedPage;
