import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Answer from './Answer';

import styles from '../../styles/partials/tries.module.scss';

const propTypes = {
    answers: PropTypes.arrayOf(PropTypes.shape({})),
    hasFoundAnswer: PropTypes.bool,
    hideWrongAnswers: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    answers: [],
    hasFoundAnswer: false,
    hideWrongAnswers: false,
    className: null,
};

function Tries({ answers, hasFoundAnswer, hideWrongAnswers, className }) {
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            {answers.map((a, i) => {
                const step = i + 1;
                const wrong = step <= answers.length ? 'wrong' : null;
                const type = step === answers.length && hasFoundAnswer ? 'right' : wrong;
                const { id, title } = a || {};

                if (step > answers.length) return false;
                if (hideWrongAnswers && type === 'wrong') return false;

                return (
                    <Answer key={id}
                        className={classNames([
                            styles.choice,
                            { [styles.rightAnswer]: type === 'right' },
                        ])}
                        type={type}
                    >
                        {title}
                    </Answer>
                );
            })}

            {!hasFoundAnswer ? (
                <div className={classNames([styles.choice, styles.choiceEmpty])} />
            ) : null}
        </div>
    );
}

Tries.propTypes = propTypes;
Tries.defaultProps = defaultProps;

export default Tries;
