import PropTypes from 'prop-types';
import React from 'react';

import Help from './Help';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function CloseIcon({ className }) {
    return <Help className={className} active />;
}

CloseIcon.propTypes = propTypes;
CloseIcon.defaultProps = defaultProps;

export default CloseIcon;
