import shuffle from 'lodash/shuffle';

// mont tremblant
import A4XT_1 from '../images/A4XT_1.jpg';
import A4XT_2 from '../images/A4XT_2.jpg';
import A4XT_3 from '../images/A4XT_3.jpg';
import A4XT_4 from '../images/A4XT_4.jpg';
// anticosti
import F74S_1 from '../images/F74S_1.jpg';
import F74S_2 from '../images/F74S_2.jpg';
import F74S_3 from '../images/F74S_3.jpg';
import F74S_4 from '../images/F74S_4.jpg';
// H2M5 / fjord du saguenay
import H2M5_1 from '../images/H2M5_1.jpg';
import H2M5_2 from '../images/H2M5_2.jpg';
import H2M5_3 from '../images/H2M5_3.jpg';
import H2M5_4 from '../images/H2M5_4.jpg';
// grands jardins
import UF27_1 from '../images/UF27_1.jpg';
import UF27_2 from '../images/UF27_2.jpg';
import UF27_3 from '../images/UF27_3.jpg';
import UF27_4 from '../images/UF27_4.jpg';
// mégantic
import N04C_1 from '../images/N04C_1.jpg';
import N04C_2 from '../images/N04C_2.jpg';
import N04C_3 from '../images/N04C_3.jpg';
import N04C_4 from '../images/N04C_4.jpg';
// gaspésie
import XD52_1 from '../images/XD52_1.jpg';
import XD52_2 from '../images/XD52_2.jpg';
import XD52_3 from '../images/XD52_3.jpg';
import XD52_4 from '../images/XD52_4.jpg';
// oka
import D3D8_1 from '../images/D3D8_1.jpg';
import D3D8_2 from '../images/D3D8_2.jpg';
import D3D8_3 from '../images/D3D8_3.jpg';
import D3D8_4 from '../images/D3D8_4.jpg';
// bic
import G96R_1 from '../images/G96R_1.jpg';
import G96R_2 from '../images/G96R_2.jpg';
import G96R_3 from '../images/G96R_3.jpg';
import G96R_4 from '../images/G96R_4.jpg';
// jacques cartier
import O10X_1 from '../images/O10X_1.jpg';
import O10X_2 from '../images/O10X_2.jpg';
import O10X_3 from '../images/O10X_3.jpg';
import O10X_4 from '../images/O10X_4.jpg';
// mont orford
import ZP2E_1 from '../images/ZP2E_1.jpg';
import ZP2E_2 from '../images/ZP2E_2.jpg';
import ZP2E_3 from '../images/ZP2E_3.jpg';
import ZP2E_4 from '../images/ZP2E_4.jpg';
// hautes gorges de la rivière malbaie
import BB3K_1 from '../images/BB3K_1.jpg';
import BB3K_2 from '../images/BB3K_2.jpg';
import BB3K_3 from '../images/BB3K_3.jpg';
import BB3K_4 from '../images/BB3K_4.jpg';
// opemican
import P0M1_1 from '../images/P0M1_1.jpg';
import P0M1_2 from '../images/P0M1_2.jpg';
import P0M1_3 from '../images/P0M1_3.jpg';
import P0M1_4 from '../images/P0M1_4.jpg';


function getStepImage(img, credits = null) {
    return {
        url: img,
        width: 1280,
        height: 800,
        credits
    };
}

export const routes = {
    default: {
        home: '/',
        game: '/:game',
        finished: '/finished',
    },
};

export const misc = {
    domain: 'https://quizsepaq.urbania.ca',
};

export const games = shuffle([
    {
        id: 'mont-tremblant',
        slug: '1',
        title: 'Parc national du Mont-Tremblant',
        href: 'https://www.sepaq.com/pq/mot',
        description:
            '<p>Âgé de plus de 125 ans, le parc national du Mont-Tremblant est le tout premier parc qui a été créé au Québec. Avec ses six grandes rivières, ses 400 lacs et ruisseaux, il est un véritable paradis pour les canoteurs! Cliquez ici pour plus d’infos!</p>',
        steps: [
            getStepImage(A4XT_1, 'Mathieu Dupuis'),
            getStepImage(A4XT_2, 'Steve Deschênes'),
            getStepImage(A4XT_3, 'Steve Deschênes'),
            getStepImage(A4XT_4, 'Mathieu Dupuis'),
        ],
    },
    {
        id: 'anticosti',
        slug: '2',
        title: 'Parc national d’Anticosti',
        href: 'https://www.sepaq.com/pq/pan',
        description:
            '<p>Chutes, canyons, falaises, grottes secrètes… La liste des attraits à visiter sur le parc national d’Anticosti est longue. Avec plus de 125 km à parcourir, la randonnée pédestre est l’activité de choix pour observer ces merveilles de près.</p>',
        steps: [
            getStepImage(F74S_1, 'SÉPAQ'),
            getStepImage(F74S_2, 'Mikaël Rondeau'),
            getStepImage(F74S_3, 'Mikaël Rondeau'),
            getStepImage(F74S_4, 'Dominic Boudreault'),
        ],
    },
    {
        id: 'fjord-du-saguenay',
        slug: '3',
        title: 'Parc national du Fjord-du-Saguenay',
        href: 'https://www.sepaq.com/pq/sag',
        description:
            '<p>C’est lorsque la mer a envahi une ancienne vallée glaciaire qu’est né ce que l’on nomme aujourd’hui le parc national du Fjord-du-Saguenay. Avec ses paysages grandioses et son ouverture sur la mer, le parc est aujourd’hui le lieu de rencontre de centaines d’oiseaux migrateurs et de bélugas que l’on peut observer du haut du fjord tout comme en kayak.</p>',
        steps: [
            getStepImage(H2M5_1, 'Jean-Pierre Huard'),
            getStepImage(H2M5_2, 'SÉPAQ'),
            getStepImage(H2M5_3, 'Paul Dussault'),
            getStepImage(H2M5_4, 'Boran Richard'),
        ],
    },
    {
        id: 'grands-jardins',
        slug: '4',
        title: 'Parc national des Grands-Jardins',
        href: 'https://www.sepaq.com/pq/grj',
        description:
            '<p>Bien que le parc national des Grands-Jardins soit surtout reconnu pour ses grandes montagnes tapissées de lichen vert, il se retrouve également dans les favoris des pêcheurs. Que vous aimiez la pêche-camping ou la pêche à la journée, toutes les options sont possibles!</p>',
        steps: [
            getStepImage(UF27_1, 'Mathieu Dupuis'),
            getStepImage(UF27_2, 'Steve Deschênes'),
            getStepImage(UF27_3, 'SÉPAQ'),
            getStepImage(UF27_4, 'SÉPAQ'),
        ],
    },
    {
        id: 'mont-megantic',
        slug: '5',
        title: 'Parc national du Mont-Mégantic',
        href: 'https://www.sepaq.com/pq/mme',
        description:
            '<p>Si les ciels étoilés vous font rêver, le parc national du Mont-Mégantic est votre nouveau parc préféré! C’est dans cette toute première Réserve internationale de ciel étoilé que l’on retrouve les meilleures conditions pour profiter des beautés de la nuit en plein air.</p>',
        steps: [
            getStepImage(N04C_1, 'SÉPAQ'),
            getStepImage(N04C_2, 'SÉPAQ'),
            getStepImage(N04C_3, 'SÉPAQ'),
            getStepImage(N04C_4, 'SÉPAQ'),
        ],
    },
    {
        id: 'gaspesie',
        slug: '6',
        title: 'Parc national de la Gaspésie',
        href: 'https://www.sepaq.com/pq/gas',
        description:
            '<p>Créé en 1937, le parc national de la Gaspésie demeure à ce jour un lieu de prédilection pour les amateurs d’expéditions. C’est particulièrement en s’aventurant dans l’arrière-pays que l’on peut découvrir l’immensité des paysages et partager la nature avec la dernière population de caribous de la région.</p>',
        steps: [
            getStepImage(XD52_1, 'SÉPAQ'),
            getStepImage(XD52_2, 'Daniel Desmarais'),
            getStepImage(XD52_3, 'Luc Rousseau'),
            getStepImage(XD52_4, 'SÉPAQ'),
        ],
    },
    {
        id: 'oka',
        slug: '8',
        title: 'Parc national d’Oka',
        href: 'https://www.sepaq.com/pq/oka',
        description:
            '<p>Situé à moins d’une heure de la région métropolitaine, le parc national d’Oka est la destination idéale pour une brève escapade. Que ce soit pour pratiquer la voile, faire un tour de pédalo, découvrir le surf à pagaie ou simplement se reposer sur la plage, ce parc a tout pour satisfaire les amateurs de sports nautiques.</p>',
        steps: [
            getStepImage(D3D8_1, 'Paul Dussault'),
            getStepImage(D3D8_2, 'SÉPAQ'),
            getStepImage(D3D8_3, 'Site du gouvernement du Québec'),
            getStepImage(D3D8_4, 'SÉPAQ'),
        ],
    },
    {
        id: 'bic',
        slug: '9',
        title: 'Parc national du Bic',
        href: 'https://www.sepaq.com/pq/bic',
        description:
            '<p>Dans le palmarès des plus beaux couchers de soleil au monde, on retrouve sans faute ceux du parc national du Bic. Avec ses paysages dignes d’une carte postale et sa vue sur l’estuaire du Saint-Laurent, le parc accueille chaque année les plus grands amateurs de kayak de mer… Et des centaines de phoques!</p>',
        steps: [
            getStepImage(G96R_1, 'Paul Dussault'),
            getStepImage(G96R_2, 'SÉPAQ'),
            getStepImage(G96R_3, 'Aventure Archipel'),
            getStepImage(G96R_4, 'Mathieu Dupuis'),
        ],
    },
    {
        id: 'jacques-cartier',
        slug: '10',
        title: 'Parc national de la Jacques-Cartier',
        href: 'https://www.sepaq.com/pq/jac',
        description:
            '<p>À seulement 30 minutes de la ville de Québec, le parc national de la Jacques-Cartier est occupé par l’une des plus belles vallées glaciaires au Québec. Avec une rivière qui se faufile entre les montagnes et de hauts plateaux recouverts de conifères, se laisser bercer en chambre à air sur la rivière est la meilleure façon d’admirer le paysage.</p>',
        steps: [
            getStepImage(O10X_1, 'Mikaël Rondeau'),
            getStepImage(O10X_2, 'SÉPAQ'),
            getStepImage(O10X_3, 'SÉPAQ'),
            getStepImage(O10X_4, 'Mathieu Dupuis'),
        ],
    },
    {
        id: 'mont-orford',
        slug: '11',
        title: 'Parc national du Mont-Orford',
        href: 'https://www.sepaq.com/pq/mor',
        description:
            '<p>Nouvellement prolongés, les sentiers de vélo de montagne du parc national du Mont-Orford sont l’endroit idéal pour s’initier au sport et admirer la beauté de la région. Vous aurez peut-être même la chance de croiser des cerfs de Virginie et de grands hérons dans leur habitat naturel!</p>',
        steps: [
            getStepImage(ZP2E_1, 'Mathieu Dupuis'),
            getStepImage(ZP2E_2, 'Mathieu Dupuis'),
            getStepImage(ZP2E_3, 'SÉPAQ'),
            getStepImage(ZP2E_4, 'SÉPAQ'),
        ],
    },
    {
        id: 'hautes-gorges-de-la-riviere-malbaie',
        slug: '12',
        title: 'Parc national des Hautes-Gorges-de-la-Rivière-Malbaie',
        href: 'https://www.sepaq.com/pq/hgo',
        description:
            '<p>Quoique bien reconnu pour le légendaire sentier de l’Acropole-des-Draveurs, le parc national des Hautes-Gorges-de-la-rivière-Malbaie déborde d’activités plein air. Les excursions guidées en canot pneumatique sur les rapides de la rivière proposent des parcours à couper le souffle pour découvrir le parc sous un autre point de vue.</p>',
        steps: [
            getStepImage(BB3K_1, 'SÉPAQ'),
            getStepImage(BB3K_2, 'SÉPAQ'),
            getStepImage(BB3K_3, 'SÉPAQ'),
            getStepImage(BB3K_4, 'Steve Deschênes'),
        ],
    },
    {
        id: 'opemican',
        slug: '13',
        title: 'Parc national d’Opémican',
        href: 'https://www.sepaq.com/pq/ope',
        description:
            '<p>Le parc Opémican, ouvert depuis seulement 2018, est le petit dernier de la Sépaq. Situé dans la région de l’Abitibi-Témiscamingue, il est la destination idéale pour les amateurs de surf à pagaie. C’est plus précisément dans la baie d’Opémican que les conditions et le paysage sont les meilleurs pour découvrir cette activité.</p>',
        steps: [
            getStepImage(P0M1_1, 'Mathieu Dupuis'),
            getStepImage(P0M1_2, 'SÉPAQ'),
            getStepImage(P0M1_3, 'SÉPAQ'),
            getStepImage(P0M1_4, 'SÉPAQ'),
        ],
    },
]);

export default { routes, misc, games };
