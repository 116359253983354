import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useState, useEffect } from 'react';

import { useResults } from '../context/GameContext';
import ArrowIcon from '../icons/Arrow';
import HelpIcon from '../icons/Help';
import Logo from '../icons/Logo';
import Pin from '../partials/Pin';

import styles from '../../styles/layouts/main.module.scss';

const propTypes = {
    children: PropTypes.node.isRequired,
};

const defaultProps = {};

function MainLayout({ children }) {
    const [showHelp, setShowHelp] = useState(false);
    const { finished } = useResults();

    const onClickShowHelp = useCallback(() => {
        gtag('event', 'click_show_help', {
            show: !showHelp,
        });

        window.localStorage.setItem('SEPAQ_USER_HAS_DISMISSED_HELP', 1);
        setShowHelp((t) => !t);
    }, [showHelp, setShowHelp]);

    useEffect(() => {
        const dismissed = parseInt(
            window.localStorage.getItem('SEPAQ_USER_HAS_DISMISSED_HELP'),
            10,
        );

        if (dismissed !== 1) {
            setShowHelp(true);
        }
    }, []);

    return (
        <div className={classNames([styles.container])}>
            <header className={styles.header}>
                <Logo className={styles.logo} />

                {!finished ? (
                    <button className={styles.helpBtn} type="button" onClick={onClickShowHelp}>
                        <HelpIcon active={showHelp} />
                    </button>
                ) : null}
            </header>
            <div className={styles.content}>
                {showHelp ? (
                    <div className={styles.instructions}>
                        <h2>Comment jouer</h2>
                        <ul>
                            <li>
                                Utilise l’image pour deviner le nom exact du parc national de la
                                Sépaq.
                            </li>
                            <li>
                                Si tu te trompes, une image supplémentaire du parc national te sera
                                donnée en indice.
                            </li>
                            <li>
                                Tu as <strong>4</strong> essais pour réussir.
                            </li>
                            <li>
                                <span>Tu peux cliquer sur les boutons</span>
                                <Pin
                                    className={styles.pin}
                                    pinClassName={styles.pinShape}
                                    step={1}
                                    type="wrong"
                                />
                                <span>pour voir les photos précendentes.</span>
                            </li>
                        </ul>

                        <p>
                            <i>Bonne chance!</i>
                        </p>

                        <button className={styles.backBtn} type="button" onClick={onClickShowHelp}>
                            <ArrowIcon className={styles.backArrow} />
                            Retour au jeu
                        </button>

                        <a
                            className={styles.sepaqLink}
                            href="https://ad.doubleclick.net/ddm/trackclk/N10035.1984322URBANIA/B27722919.339302390;dc_trk_aid=531665512;dc_trk_cid=173935086;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd="
                            target="_blank"
                            rel="noreferrer"
                        >
                            <svg className={styles.sepaqLogo} viewBox="0 0 192.24 58.85">
                                <path
                                    d="M88.22,26.15h-6.53c-.1-2.59-1.75-4-4.07-4s-3.74,1.23-3.74,3.41c0,2.46,1.8,3.23,5.82,4.59,7,2.32,9.51,5.09,9.51,9.87,0,6.14-4.73,9.64-10.98,9.64-7.57,0-11.5-4.27-11.64-10.32h6.53c.14,3.14,1.89,4.87,5.2,4.87,2.79,0,4.35-1.5,4.35-3.64s-1.75-3.37-5.87-4.68c-6.01-1.91-9.46-4.46-9.46-9.78s3.93-9.46,10.46-9.46c6.15,0,10.17,3.18,10.41,9.51Z"
                                    fill="#231f20"
                                />
                                <path
                                    d="M113.39,37.43c0-6.17-2.72-10.07-7.03-11.45l4.35-4.43h-7.31l-4.34,4.4c-4.83,1.47-7.82,5.95-7.82,11.57,0,7.28,4.64,12.01,11.46,12.01,5.55,0,9.14-2.87,10.55-7.73h-5.73c-.64,1.77-2.37,2.68-4.68,2.68-3.23,0-5.32-2.14-5.5-5.18h16.05v-1.86Zm-16.05-2.18c.27-2.87,2.23-4.91,5.23-4.91s4.82,1.91,4.91,4.91h-10.14Z"
                                    fill="#231f20"
                                />
                                <path
                                    d="M140.16,37.52c0,6.78-4.5,12.05-10.73,12.05-3.32,0-5.73-1.36-6.91-3.05v12.32h-5.87V26.02h5.05l.41,3.09c1.32-1.86,3.41-3.59,7.37-3.59,6.19,0,10.69,5.23,10.69,12.01Zm-17.78-.09v.27c.05,4.09,3,6.32,5.87,6.32,3.23,0,5.82-2.55,5.82-6.5s-2.5-6.5-5.82-6.5c-2.91,0-5.87,2.27-5.87,6.41Z"
                                    fill="#231f20"
                                />
                                <path
                                    d="M160.54,29.11l.41-3.09h5.05v23.44h-5.05l-.41-3.48c-1.32,1.86-3.41,3.59-7.37,3.59-6.18,0-10.69-5.28-10.69-12.05s4.5-12.01,10.69-12.01c3.96,0,6.05,1.73,7.37,3.59Zm-11.96,8.41c0,4,2.5,6.55,5.82,6.55,2.91,0,5.87-2.27,5.87-6.41v-.23c0-4.14-2.96-6.41-5.87-6.41-3.32,0-5.82,2.55-5.82,6.5Z"
                                    fill="#231f20"
                                />
                                <path
                                    d="M186.78,29.11l.41-3.09h5.05V58.85h-5.87v-12.32c-1.18,1.68-3.59,3.05-6.91,3.05-6.23,0-10.73-5.28-10.73-12.05s4.5-12.01,10.69-12.01c3.96,0,6.05,1.73,7.37,3.59Zm-11.96,8.41c0,3.96,2.59,6.5,5.82,6.5,2.87,0,5.82-2.23,5.87-6.32v-.27c0-4.14-2.96-6.41-5.87-6.41-3.32,0-5.82,2.55-5.82,6.5Z"
                                    fill="#231f20"
                                />
                                <path
                                    d="M49.48,58.85l-9.34-9.36c6.69-4.39,11.08-12.13,11.08-20.17,0-12.5-10.17-23.43-22.66-23.43S5.9,16.83,5.9,29.32c0,6.04,2.54,11.94,6.68,16.44L28.62,29.71l7.07,7.14s1.71-2.14,1.71-4.78c0-2.25-.93-4.32-2.72-6.11-1.23-1.28-6.09-6.11-6.09-6.11l-14.45,14.53c-.71-5.06,.28-8.52,4.1-12.57,.64-.68,6.41-6.5,8.51-8.4l1.84-1.76,1.92,1.78c2.06,1.94,8.36,8.27,8.42,8.33,2.88,2.88,4.43,6.58,4.36,10.42-.07,3.7-1.63,7.27-4.4,10.04-.96,.96-2.05,1.78-3.44,2.63l-6.89-6.89-15.81,15.81C4.94,47.95,0,39.09,0,29.32,0,13.57,12.81,0,28.56,0s28.56,13.57,28.56,29.32c0,7.43-3.08,14.98-8.33,20.57l8.91,8.95h-8.21Z"
                                    fill="#61a60e"
                                />
                            </svg>
                        </a>
                    </div>
                ) : null}
            </div>
            <main className={styles.content}>{children}</main>
        </div>
    );
}

MainLayout.propTypes = propTypes;
MainLayout.defaultProps = defaultProps;

export default MainLayout;
