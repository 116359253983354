import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// import styles from '../../styles/icons/logo.module.scss';

const propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    active: false,
    className: null,
};

function HelpIcon({ active, className }) {
    return (
        <svg
            className={classNames([
                {
                    [className]: className !== null,
                },
            ])}
            width="30"
            height="30"
            viewBox="0 0 30 30"
        >
            <path fill="currentColor" d="M15,0C6.72,0,0,6.72,0,15s6.72,15,15,15,15-6.72,15-15C30,6.72,23.28,0,15,0Zm0,27c-6.63,0-12-5.37-12-12S8.37,3,15,3s12,5.37,12,12c0,6.62-5.38,11.99-12,12Z" />
            {!active ? (
                <path fill="currentColor" d="M14.99,14.95c.32-.42,.88-.84,1.36-1.48,.24-.32,.34-.68,.34-1.18,0-1.28-.7-2.02-1.86-2.02-1,0-1.7,.78-1.72,2.1h-2.61c.02-2.77,1.94-4.61,4.49-4.61s4.51,1.84,4.51,4.37c0,1.88-.96,2.5-1.88,3.35-.9,.84-1.42,1.6-1.46,2.87h-2.5c0-1.38,.48-2.35,1.32-3.41Zm-1.32,4.61h2.5v2.67h-2.5v-2.67Z" />
            ): null}
            {active ? (
                <>
                    <rect
                        fill="currentColor"
                        x="7.32"
                        y="13.5"
                        width="15.35"
                        height="3"
                        transform="translate(15 -6.21) rotate(45)"

                    />
                    <rect
                        fill="currentColor"
                        x="7.32"
                        y="13.5"
                        width="15.35"
                        height="3"
                        transform="translate(36.21 15) rotate(135)"
                    />
                </>
            ): null}
        </svg>
    );
}

HelpIcon.propTypes = propTypes;
HelpIcon.defaultProps = defaultProps;

export default HelpIcon;
