/* eslint-disable react/jsx-props-no-spreading */
import shuffle from 'lodash/shuffle';
import PropTypes from 'prop-types';
import React, { useContext, useState, useMemo, useCallback } from 'react';

import * as AppPropTypes from '../../lib/PropTypes';
import { games } from '../../lib/data';

export const GameContext = React.createContext(null);

export const useGameContext = () => useContext(GameContext) || {};

export const useGame = () => {
    const { game, setGame, getGameBySlug, getRandomGame } = useGameContext();
    return { game, setGame, getGameBySlug, getRandomGame };
};

export const useResults = () => {
    const { results, setResults, finished, setFinished } = useGameContext();
    return { results, setResults, finished, setFinished };
};

const propTypes = {
    children: PropTypes.node.isRequired,
    game: AppPropTypes.game,
    results: PropTypes.shape(),
};

const defaultProps = {
    game: null,
    results: {},
};

export const GameProvider = ({ children, game: initialGame, results: initialResults }) => {
    const [game, setGame] = useState(initialGame);
    const [results, setResults] = useState(initialResults);
    const [finished, setFinished] = useState(false);

    const getRandomGame = useCallback(
        (currentSlug) => {
            const done = Object.keys(results) || [];
            const randomGames = shuffle(games.filter((g) => g.slug !== currentSlug && !done.includes(g.id)));

            if (randomGames.length === 0) {
                setFinished(true);
                return null;
            }
            return randomGames[0];
        },
        [results, setFinished],
    );

    const getGameBySlug = useCallback((currentSlug) => {
        const currentGame = games.find((g) => g.slug === currentSlug) || null;
        return currentGame;
    }, []);

    const value = useMemo(
        () => ({
            game,
            setGame,
            getRandomGame,
            getGameBySlug,
            results,
            setResults,
            finished,
            setFinished,
        }),
        [game, setGame, getRandomGame, getGameBySlug, results, setResults, finished, setFinished],
    );
    return <GameContext.Provider value={value}>{children}</GameContext.Provider>;
};

GameProvider.propTypes = propTypes;
GameProvider.defaultProps = defaultProps;
