/* eslint-disable react/no-danger */
import { useKeyboard } from '@folklore/hooks';
import classNames from 'classnames';
import copyToClipboard from 'clipboard-copy';
import shuffle from 'lodash/shuffle';
import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';

import { games, misc } from '../../lib/data';

import { useGame, useResults } from '../context/GameContext';
import ArrowIcon from '../icons/Arrow';
import CloseIcon from '../icons/Close';
import Tick from '../icons/Right';
import Answer from '../partials/Answer';
import GuessThePark from '../partials/GuessThePark';
import PageTitle from '../partials/PageTitle';
import Tries from '../partials/Tries';

import styles from '../../styles/pages/game.module.scss';

function Game() {
    const refParksList = useRef(null);

    const { game, getRandomGame } = useGame();
    const { id = null, slug = null, steps = null } = game || {};
    const { results, setResults, finished } = useResults();

    const [currentSearch, setCurrentSearch] = useState(''); // the textfield contents
    const [currentChoice, setCurrentChoice] = useState(null); // when a choice was clicked
    const [currentScore, setCurrentScore] = useState(1); // goes to 5 (meaning user hasn't found it in 4!)
    const [hasFoundAnswer, setHasFoundAnswer] = useState(false); // switch to true to indicate that user has found the answer
    const [ended, setEnded] = useState(false); // switch to true when the game has ended: user has either won or lost
    const [showParksList, setShowParksList] = useState(false); // displaying the list of parks
    const [answers, setAnswers] = useState([]); // savings the answers to display in list
    const [resultsCopiedToClipboard, setResultsCopiedToClipboard] = useState(false); // show confirm message
    const [shareMessage, setShareMessage] = useState(null); // text to copy, for user to share on whatever...

    // Information about the right answer
    const {
        title: rightAnswerTitle = null,
        description: rightAnswerDescription = null,
        href: rightAnswerMoreLinkHref = null,
    } = useMemo(() => games.find((g) => g.id === id) || {}, [id, game, games]);

    // when focusing the search field
    const onFocusSearch = useCallback(() => {
        setShowParksList(true);
    }, [setShowParksList]);

    // update the value of the field
    const onChangeSearch = useCallback(
        (e) => {
            const search = e.target?.value;
            setCurrentSearch(search);
            setShowParksList(true);
        },
        [setCurrentSearch, setShowParksList],
    );

    // click on a choice in the ParksList list
    const onClickPark = useCallback(
        (choice) => {
            gtag('event', 'click_park', {
                choice
            });
            setCurrentSearch('');
            setCurrentChoice(choice);
            setShowParksList(false);
        },
        [setCurrentSearch, setShowParksList, setCurrentChoice],
    );

    // click on the currently selected choice/park option to select another
    const onClickChoice = useCallback(() => {
        gtag('event', 'clear_choice', {
            clearedChoice: currentChoice,
        });
        setShowParksList(true);
        setCurrentChoice(null);
    }, [setCurrentSearch, setShowParksList, currentChoice, setCurrentChoice]);

    // submitting an answer
    const onClickSubmit = useCallback(
        (e) => {
            e.preventDefault();
            const { id: choiceId } = currentChoice || {};

            setAnswers([...answers, currentChoice]);

            if (choiceId === id) {
                gtag('event', 'submit_answer', {
                    is_right_answer: true,
                    answer: currentChoice,
                    score: currentScore,
                });
                setHasFoundAnswer(true);
                setEnded(true);
                setResults({
                    ...results,
                    [id]: currentScore,
                });
            } else {
                gtag('event', 'submit_answer', {
                    is_right_answer: false,
                    answer: currentChoice,
                    score: currentScore,
                });
                setResults({
                    ...results,
                    [id]: currentScore + 1,
                });
                setCurrentChoice(null);
                setCurrentSearch('');
                setCurrentScore(currentScore + 1);
                setEnded(currentScore === 4);
            }
        },
        [
            currentChoice,
            id,
            results,
            setResults,
            setCurrentChoice,
            setCurrentSearch,
            setCurrentScore,
            setHasFoundAnswer,
            setEnded,
        ],
    );

    const onSubmitAnswer = useCallback((e) => {
        e.preventDefault();
    }, []);

    // share by copying to clipboard
    const onClickShare = useCallback(() => {
        const msg = `Sépa(q) c’que tu penses #${slug} / 🏕`;
        const circles = [1, 2, 3, 4]
            .map((p, i) => {
                const step = i + 1;
                const wrong = step <= answers.length ? '🟠' : '⚪️';
                const type = step === answers.length && hasFoundAnswer ? '🟢' : wrong;

                return type;
            })
            .join('');
        const url = ` / ${misc.domain}/${slug}`;
        const sharedResults = msg + circles + url;

        copyToClipboard(sharedResults).then(() => {
            setResultsCopiedToClipboard(true);
            setShareMessage(sharedResults);

            gtag('event', 'copy_share_link', {
                msg: sharedResults,
                park: currentChoice,
            });

            setTimeout(() => {
                setResultsCopiedToClipboard(false);
            }, 2000);
        });
    }, [slug, answers, currentChoice, hasFoundAnswer, setResultsCopiedToClipboard]);

    // Keyboard shortcuts
    const keyMap = useMemo(
        () => ({
            Escape: () => {
                if (showParksList) {
                    setShowParksList(false);
                }
            },
        }),
        [showParksList, setShowParksList],
    );

    useKeyboard(keyMap);

    const onClickOutsideParksList = useCallback(
        (e) => {
            if (refParksList.current && !refParksList.current.contains(e.target) && showParksList) {
                setShowParksList(currentChoice === null);
            }
        },
        [currentChoice, refParksList.current, showParksList, setShowParksList],
    );

    const onClickLearnMore = useCallback(() => {
        gtag('event', 'click_see_more', {
            park_name: rightAnswerTitle,
            url: rightAnswerMoreLinkHref,
        });
    }, [currentChoice]);

    const nextGameSlug = useMemo(() => {
        const nextGame = getRandomGame(slug);
        return nextGame !== null ? nextGame.slug : null;
    }, [slug]);

    useEffect(() => {
        document.addEventListener('click', onClickOutsideParksList);
        return () => {
            document.removeEventListener('click', onClickOutsideParksList);
        };
    }, [onClickOutsideParksList]);

    useEffect(() => {
        const { title = null } = game || {};
        if (id !== null) {
            gtag('event', 'navigate_to_next', {
                game: { id, title },
            });
        }

        setCurrentSearch('');
        setCurrentChoice(null);
        setCurrentScore(1);
        setHasFoundAnswer(false);
        setShareMessage(null);
        setEnded(false);
        setShowParksList(false);
        setAnswers([]);
    }, [game]);

    const userAnswersIds = useMemo(() => answers.map((a) => a.id), [answers]);
    const parksList = useMemo(
        () =>
            shuffle(games)
                .map((s) => {
                    const { id: key, title } = s || {};
                    const hidden = !title.toLowerCase().includes(currentSearch.toLowerCase());
                    const hasAlreadyChosenPark = userAnswersIds.includes(key);

                    if (hidden || hasAlreadyChosenPark) return false;

                    return (
                        <button
                            key={key}
                            className={styles.searchSuggestionBtn}
                            type="button"
                            onClick={() => onClickPark({ id: key, title })}
                            tabIndex={hidden ? '-1' : null}
                        >
                            <svg
                                className={styles.pin}
                                width="34.68"
                                height="45.91"
                                viewBox="0 0 34.68 45.91"
                            >
                                <path d="M15.61,44.51C2.87,26.03,.5,24.13,.5,17.34,.5,8.04,8.04,.5,17.34,.5s16.84,7.54,16.84,16.84c0,6.79-2.37,8.69-15.11,27.16-.66,.96-1.97,1.19-2.93,.53-.21-.14-.39-.32-.53-.53h0Z" />
                            </svg>
                            {title}
                        </button>
                    );
                })
                .filter((s) => s !== false),
        [answers, currentSearch, onClickPark],
    );

    return (
        <div className={styles.container}>
            <PageTitle className={styles.title} title="Sépaq c’que tu penses" />
            <div className={styles.intro}>
                <strong>
                    T’es un.e grand.e explorateur.trice? Tu penses connaître tous les parcs
                    nationaux du Québec? C’est le moment de prouver que tu sais ou que tu SÉ-PA(Q)!
                </strong>{' '}
                (OK, on arrête avec les jeux de mots)
            </div>
            <div className={styles.inner}>
                <GuessThePark
                    game={game}
                    steps={steps}
                    currentScore={currentScore}
                    hasFoundAnswer={hasFoundAnswer}
                />

                <div ref={refParksList} className={styles.search} onSubmit={onSubmitAnswer}>
                    <form className={styles.searchForm}>
                        <div className={styles.searchContainer}>
                            {currentChoice === null && !ended ? (
                                <div className={styles.searchField}>
                                    <svg
                                        className={classNames([
                                            styles.searchIcon,
                                            { [styles.isSearching]: showParksList },
                                        ])}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="21.82"
                                        height="21.69"
                                        viewBox="0 0 21.82 21.69"
                                    >
                                        <path
                                            d="M21.53,19.87l-3.81-3.76c3.41-4.3,2.69-10.55-1.6-13.96S5.57-.54,2.15,3.76C-1.26,8.06-.54,14.31,3.76,17.72c3.65,2.9,8.83,2.87,12.45-.08l3.76,3.71c.41,.43,1.08,.45,1.51,.04,.43-.41,.45-1.09,.04-1.52Zm-11.62-2.15c-4.33,0-7.85-3.51-7.85-7.84,0-4.33,3.51-7.85,7.84-7.85,4.33,0,7.85,3.51,7.85,7.84,0,2.08-.83,4.08-2.3,5.55-1.47,1.48-3.47,2.3-5.55,2.3Z"
                                            fill="currentColor"
                                            isolation="isolate"
                                        />
                                    </svg>
                                    <input
                                        type="text"
                                        className={styles.searchInput}
                                        onFocus={onFocusSearch}
                                        onChange={onChangeSearch}
                                        value={currentSearch}
                                        placeholder="Rechercher un parc national"
                                    />
                                </div>
                            ) : null}

                            {currentChoice !== null && !ended ? (
                                <button
                                    type="button"
                                    className={styles.searchChoice}
                                    onClick={onClickChoice}
                                >
                                    <svg
                                        className={styles.pin}
                                        width="34.68"
                                        height="45.91"
                                        viewBox="0 0 34.68 45.91"
                                    >
                                        <path d="M15.61,44.51C2.87,26.03,.5,24.13,.5,17.34,.5,8.04,8.04,.5,17.34,.5s16.84,7.54,16.84,16.84c0,6.79-2.37,8.69-15.11,27.16-.66,.96-1.97,1.19-2.93,.53-.21-.14-.39-.32-.53-.53h0Z" />
                                    </svg>
                                    <div className={styles.searchChoiceTitle}>
                                        {currentChoice.title}
                                    </div>
                                    <CloseIcon className={styles.searchClear} />
                                </button>
                            ) : null}
                        </div>
                        {currentChoice !== null && !ended ? (
                            <button
                                className={styles.searchSubmit}
                                type="submit"
                                onClick={onClickSubmit}
                            >
                                Soumettre
                            </button>
                        ) : null}
                    </form>

                    {showParksList ? (
                        <div className={styles.searchList}>
                            {parksList}
                            {parksList.length === 0 ? (
                                <div className={styles.searchEmpty}>
                                    Aucun parc trouvé avec ce nom!
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                </div>

                <div className={styles.end}>
                    {ended && hasFoundAnswer ? (
                        <h2 className={styles.answerHeading}>Bingo! Bien joué!</h2>
                    ) : null}
                    {ended && !hasFoundAnswer ? (
                        <h2 className={styles.answerHeading}>
                            Malheureusement, t’es dans l’champ… la bonne réponse était{' '}
                        </h2>
                    ) : null}

                    {!ended ? (
                        <Tries
                            className={styles.tries}
                            answers={answers}
                            hasFoundAnswer={hasFoundAnswer}
                            hideWrongAnswers={ended}
                        />
                    ) : null}
                    {ended ? (
                        <div className={styles.results}>
                            {rightAnswerTitle !== null ? (
                                <Answer className={styles.tries} type="right">
                                    {rightAnswerTitle}
                                </Answer>
                            ) : null}

                            <div className={styles.rightAnswerDescription}>
                                {rightAnswerDescription !== null ? (
                                    <div
                                        dangerouslySetInnerHTML={{ __html: rightAnswerDescription }}
                                    />
                                ) : null}

                                <a
                                    className={styles.rightAnswerMoreLink}
                                    onClick={() =>
                                        onClickLearnMore(
                                            rightAnswerMoreLinkHref !== null
                                                ? rightAnswerMoreLinkHref
                                                : 'https://www.sepaq.com/parcs-nationaux',
                                        )
                                    }
                                    href={
                                        rightAnswerMoreLinkHref !== null
                                            ? rightAnswerMoreLinkHref
                                            : 'https://www.sepaq.com/parcs-nationaux'
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Voir plus
                                </a>
                            </div>

                            <div className={styles.actions}>
                                <button
                                    className={classNames([
                                        styles.shareBtn,
                                        { [styles.wasCopied]: resultsCopiedToClipboard },
                                    ])}
                                    type="button"
                                    onClick={onClickShare}
                                >
                                    <div className={styles.shareLabel}>Partage ton pointage</div>
                                    <div className={styles.shareCopied}>
                                        Pointage copié! <Tick />
                                    </div>
                                </button>
                                {!finished ? (
                                    <Link
                                        className={styles.nextChallengeLink}
                                        to={`/${nextGameSlug}`}
                                    >
                                        Prochain défi
                                        <ArrowIcon className={styles.nextChallengeLinkIcon} />
                                    </Link>
                                ) : null}
                            </div>
                            {shareMessage !== null ? (
                                <button
                                    type="button"
                                    className={styles.shareMessage}
                                    onClick={onClickShare}
                                >
                                    {shareMessage}
                                </button>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default Game;
