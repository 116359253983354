import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// import styles from '../../styles/icons/logo.module.scss';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function LogoIcon({ className }) {
    return (
        <svg
            className={classNames([
                {
                    [className]: className !== null,
                },
            ])}
            xmlns="http://www.w3.org/2000/svg"
            width="385.86"
            height="118.77"
            viewBox="0 0 385.86 118.77"
        >
            <path fill="currentColor" d="M21.17,24.04c-.37-.08-.77-.18-1.22-.31-.41-.08-.86-.16-1.37-.25-.51-.08-1.09-.12-1.74-.12-1.71,0-2.88,.25-3.51,.75-.63,.5-.95,1.16-.95,2,0,1.1,.45,1.97,1.34,2.62,.89,.65,2.02,1.25,3.39,1.8,1.36,.55,2.84,1.13,4.42,1.74,1.59,.61,3.06,1.39,4.42,2.35,1.36,.96,2.49,2.18,3.39,3.66,.89,1.48,1.34,3.39,1.34,5.7,0,1.99-.37,3.82-1.1,5.49-.73,1.67-1.86,3.11-3.39,4.33-1.53,1.22-3.46,2.18-5.8,2.87-2.34,.69-5.11,1.04-8.33,1.04-2.81,0-5.06-.24-6.77-.73s-2.87-1.04-3.48-1.65c-.29-.28-.52-.57-.7-.85-.18-.28-.34-.61-.46-.98-.12-.37-.2-.79-.24-1.28-.04-.49-.06-1.1-.06-1.83v-1.92c0-.39,.02-.92,.06-1.62,.08-1.18,.43-2.03,1.04-2.56,.61-.53,1.4-.79,2.38-.79,1.87,0,3.17,.99,3.9,2.96l.98,2.53c.41,.16,.83,.31,1.28,.43,.41,.08,.89,.16,1.46,.25,.57,.08,1.22,.12,1.95,.12,1.83,0,3.1-.3,3.81-.9,.71-.6,1.07-1.4,1.07-2.39,0-.85-.26-1.59-.79-2.2-.53-.61-1.22-1.15-2.07-1.62-.85-.47-1.83-.92-2.93-1.34-1.1-.43-2.22-.89-3.35-1.4-1.14-.51-2.26-1.08-3.35-1.71-1.1-.63-2.07-1.39-2.93-2.29-.85-.89-1.55-1.96-2.07-3.2-.53-1.24-.79-2.71-.79-4.42,0-1.83,.36-3.53,1.07-5.09,.71-1.56,1.81-2.93,3.29-4.09,1.48-1.16,3.36-2.06,5.64-2.71,2.28-.65,4.98-.98,8.11-.98,2.85,0,5.06,.23,6.65,.7,1.59,.47,2.66,.99,3.23,1.56,.28,.29,.52,.57,.7,.85,.18,.29,.34,.61,.46,.98,.12,.37,.2,.79,.24,1.28,.04,.49,.06,1.1,.06,1.83v1.83c0,.33-.02,.83-.06,1.52-.08,1.18-.43,2.03-1.04,2.56-.61,.53-1.4,.79-2.38,.79s-1.77-.25-2.38-.74-1.12-1.23-1.52-2.22l-.92-2.34Z" />
            <path fill="currentColor" d="M38.25,24.16l-1.04-.49c-1.22-.57-2.1-1.18-2.65-1.83-.55-.65-.82-1.5-.82-2.56,0-.93,.33-1.71,.98-2.32,.65-.61,1.56-.92,2.75-.92h24.83c.98,0,1.74,.11,2.29,.33,.55,.22,1.03,.54,1.43,.95,.41,.41,.67,.88,.79,1.4,.12,.53,.18,1.36,.18,2.5v2.04c0,.43-.02,.95-.06,1.56-.08,1.18-.42,2.03-1.01,2.56-.59,.53-1.41,.79-2.47,.79-.98,0-1.75-.21-2.32-.64-.57-.43-1.04-1.09-1.4-1.98l-.73-1.83h-7.75v9.46h5.31l.55-1.28c.28-.69,.69-1.18,1.22-1.46,.53-.28,1.14-.43,1.83-.43,.94,0,1.7,.27,2.29,.82,.59,.55,.88,1.35,.88,2.41v6.53c0,1.06-.3,1.86-.88,2.41-.59,.55-1.35,.82-2.29,.82-.69,0-1.3-.15-1.83-.46-.53-.3-.92-.74-1.16-1.31l-.61-1.4h-5.31v9.58h8.72l.85-2.32c.33-.89,.77-1.54,1.34-1.95,.57-.41,1.34-.61,2.32-.61,1.06,0,1.89,.27,2.5,.79,.61,.53,.94,1.38,.98,2.56,0,.49,0,.84,.03,1.07,.02,.22,.03,.43,.03,.61v2.29c0,1.14-.06,1.98-.18,2.53-.12,.55-.39,1.03-.79,1.43-.41,.41-.88,.72-1.43,.95-.55,.22-1.31,.34-2.29,.34h-25.5c-1.3,0-2.31-.34-3.02-1.04-.71-.69-1.07-1.59-1.07-2.68,0-.94,.24-1.66,.73-2.17,.49-.51,1.28-1.03,2.38-1.56l1.4-.67V24.16ZM56.67,.61c.28-.2,.56-.36,.82-.46,.26-.1,.54-.15,.82-.15,.65,0,1.28,.2,1.89,.61,.61,.41,1.16,.93,1.65,1.56,.49,.63,.87,1.32,1.16,2.07,.28,.75,.43,1.47,.43,2.17,0,.73-.14,1.32-.43,1.77-.28,.45-.73,.79-1.34,1.04l-10.43,4.03c-.37,.12-.71,.18-1.04,.18-1.06,0-1.92-.4-2.59-1.19s-1.01-1.7-1.01-2.71c0-.85,.39-1.59,1.16-2.2L56.67,.61Z" />
            <path fill="currentColor" d="M74.97,24.16l-1.04-.49c-1.22-.57-2.1-1.18-2.65-1.83-.55-.65-.82-1.5-.82-2.56,0-.93,.33-1.71,.98-2.32,.65-.61,1.57-.92,2.75-.92h17.17c2.97,0,5.52,.29,7.64,.88,2.12,.59,3.86,1.42,5.22,2.5,1.36,1.08,2.36,2.39,2.99,3.93,.63,1.55,.95,3.25,.95,5.12,0,2.12-.38,4.05-1.13,5.79-.75,1.75-1.89,3.26-3.4,4.54s-3.42,2.28-5.72,2.99c-2.3,.71-4.99,1.07-8.05,1.07h-2.02v6.04l1.89,.79c1.1,.45,1.9,.94,2.41,1.46,.51,.53,.76,1.26,.76,2.2,0,1.1-.36,1.99-1.07,2.68-.71,.69-1.72,1.04-3.02,1.04h-14.27c-1.3,0-2.31-.34-3.02-1.04-.71-.69-1.07-1.59-1.07-2.68,0-.94,.24-1.66,.73-2.17,.49-.51,1.28-1.03,2.38-1.56l1.4-.67V24.16Zm15.13,11.83c1.54,0,2.78-.5,3.69-1.49,.92-1,1.37-2.57,1.37-4.73s-.43-3.7-1.28-4.64-2.11-1.4-3.78-1.4h-2.26v12.26h2.26Z" />
            <path fill="currentColor" d="M117.52,22.75c.45-1.26,.96-2.34,1.52-3.23,.57-.89,1.27-1.62,2.1-2.17,.83-.55,1.8-.96,2.9-1.22,1.1-.26,2.38-.4,3.84-.4s2.74,.13,3.84,.4c1.1,.27,2.06,.67,2.9,1.22,.83,.55,1.53,1.28,2.1,2.2,.57,.92,1.08,2.01,1.52,3.27l8.97,26.22,1.16,.73c.89,.57,1.56,1.09,1.98,1.58,.43,.48,.64,1.17,.64,2.06,0,1.09-.36,1.98-1.07,2.66-.71,.69-1.72,1.03-3.02,1.03h-12.75c-1.3,0-2.31-.34-3.02-1.03-.71-.69-1.07-1.55-1.07-2.6,0-.85,.21-1.51,.64-2,.43-.48,1.17-1.07,2.23-1.76l.85-.54-.85-3.05h-12.81l-.98,2.99,.85,.55c1.06,.69,1.8,1.28,2.23,1.77,.43,.49,.64,1.16,.64,2.01,0,1.06-.36,1.93-1.07,2.62-.71,.69-1.72,1.04-3.02,1.04h-10c-1.3,0-2.31-.34-3.02-1.04-.71-.69-1.07-1.59-1.07-2.68,0-.89,.21-1.59,.64-2.07,.43-.49,1.09-1.02,1.98-1.59l1.16-.73,9.03-26.23Zm13.24,16.65l-1.89-6.28c-.28-.94-.57-1.87-.85-2.81-.28-.93-.53-1.79-.73-2.56-.24-.89-.49-1.77-.73-2.62h-.12c-.24,.85-.49,1.73-.73,2.62-.2,.77-.45,1.64-.73,2.59-.29,.96-.57,1.9-.85,2.84l-1.89,6.22h8.54Z" />
            <path fill="currentColor" d="M15.13,85.28h-3.96l-1.34,3.48c-.45,1.14-.96,1.95-1.52,2.44-.57,.49-1.32,.73-2.26,.73s-1.76-.26-2.35-.79c-.59-.53-.91-1.38-.95-2.56-.04-1.5-.06-2.61-.06-3.32v-2.84c0-1.18,.06-2.05,.18-2.62,.12-.57,.39-1.06,.79-1.46,.41-.41,.88-.72,1.43-.95,.55-.22,1.31-.33,2.29-.33h28.49c.98,0,1.74,.11,2.29,.33,.55,.22,1.03,.54,1.43,.95,.41,.41,.67,.9,.79,1.46s.18,1.44,.18,2.62v2.84c0,.71-.02,1.82-.06,3.32-.04,1.18-.36,2.03-.95,2.56-.59,.53-1.37,.79-2.35,.79s-1.69-.24-2.26-.73c-.57-.49-1.08-1.3-1.52-2.44l-1.34-3.48h-3.96v24.71l1.71,.73c1.1,.45,1.9,.94,2.41,1.46,.51,.53,.76,1.26,.76,2.2,0,1.1-.36,1.99-1.07,2.68-.71,.69-1.72,1.04-3.02,1.04H14.34c-1.3,0-2.31-.34-3.02-1.04-.71-.69-1.07-1.59-1.07-2.68,0-.94,.25-1.66,.76-2.17,.51-.51,1.31-1.01,2.41-1.49l1.71-.73v-24.71Z" />
            <path fill="currentColor" d="M45.82,85.15l-.55-.37c-1.1-.73-1.91-1.4-2.44-2.01-.53-.61-.79-1.44-.79-2.5,0-.93,.33-1.71,.98-2.32,.65-.61,1.56-.92,2.75-.92h13.3c1.18,0,2.08,.3,2.71,.92,.63,.61,.95,1.38,.95,2.32,0,1.06-.23,1.89-.7,2.5-.47,.61-1.29,1.26-2.47,1.95l-.73,.43v15.86c0,3.25,.59,5.59,1.77,7.01,1.18,1.42,2.72,2.14,4.64,2.14s3.42-.62,4.51-1.86c1.1-1.24,1.65-3.26,1.65-6.07v-17.08l-.73-.43c-1.18-.69-2.01-1.34-2.5-1.95-.49-.61-.73-1.44-.73-2.5,0-.93,.33-1.71,.98-2.32,.65-.61,1.57-.92,2.75-.92h11.16c1.18,0,2.09,.3,2.75,.92,.65,.61,.98,1.38,.98,2.32,0,1.06-.27,1.89-.79,2.5-.53,.61-1.34,1.28-2.44,2.01l-.55,.37v16.1c0,5.94-1.47,10.34-4.42,13.21-2.95,2.87-7.59,4.3-13.94,4.3s-10.79-1.38-13.69-4.15c-2.91-2.77-4.36-6.81-4.36-12.14v-17.32Z" />
            <path fill="currentColor" d="M106.51,85.15l-1.04-.49c-1.22-.57-2.1-1.18-2.65-1.83-.55-.65-.82-1.5-.82-2.56,0-.93,.33-1.71,.98-2.32,.65-.61,1.57-.92,2.75-.92h17.17c2.97,0,5.52,.29,7.64,.88,2.12,.59,3.86,1.42,5.22,2.5,1.36,1.08,2.36,2.39,2.99,3.93,.63,1.55,.95,3.25,.95,5.12,0,2.12-.38,4.05-1.13,5.79-.75,1.75-1.89,3.26-3.4,4.54s-3.42,2.28-5.72,2.99c-2.3,.71-4.99,1.07-8.05,1.07h-2.02v6.04l1.89,.79c1.1,.45,1.9,.94,2.41,1.46,.51,.53,.76,1.26,.76,2.2,0,1.1-.36,1.99-1.07,2.68-.71,.69-1.72,1.04-3.02,1.04h-14.27c-1.3,0-2.31-.34-3.02-1.04-.71-.69-1.07-1.59-1.07-2.68,0-.94,.24-1.66,.73-2.17,.49-.51,1.28-1.03,2.38-1.56l1.4-.67v-24.83Zm15.13,11.83c1.54,0,2.78-.5,3.69-1.49,.92-1,1.37-2.57,1.37-4.73s-.43-3.7-1.28-4.64-2.11-1.4-3.78-1.4h-2.26v12.26h2.26Z" />
            <path fill="currentColor" d="M145.98,85.15l-1.04-.49c-1.22-.57-2.1-1.18-2.65-1.83-.55-.65-.82-1.5-.82-2.56,0-.93,.33-1.71,.98-2.32,.65-.61,1.56-.92,2.75-.92h24.83c.98,0,1.74,.11,2.29,.33,.55,.22,1.03,.54,1.43,.95,.41,.41,.67,.88,.79,1.4,.12,.53,.18,1.36,.18,2.5v2.04c0,.43-.02,.95-.06,1.56-.08,1.18-.42,2.03-1.01,2.56-.59,.53-1.41,.79-2.47,.79-.98,0-1.75-.21-2.32-.64-.57-.43-1.04-1.09-1.4-1.98l-.73-1.83h-7.75v9.46h5.31l.55-1.28c.28-.69,.69-1.18,1.22-1.46,.53-.28,1.14-.43,1.83-.43,.94,0,1.7,.27,2.29,.82,.59,.55,.88,1.35,.88,2.41v6.53c0,1.06-.3,1.86-.88,2.41-.59,.55-1.35,.82-2.29,.82-.69,0-1.3-.15-1.83-.46-.53-.3-.92-.74-1.16-1.31l-.61-1.4h-5.31v9.58h8.72l.85-2.32c.33-.89,.77-1.54,1.34-1.95,.57-.41,1.34-.61,2.32-.61,1.06,0,1.89,.27,2.5,.79,.61,.53,.94,1.38,.98,2.56,0,.49,0,.84,.03,1.07,.02,.22,.03,.43,.03,.61v2.29c0,1.14-.06,1.98-.18,2.53-.12,.55-.39,1.03-.79,1.43-.41,.41-.88,.72-1.43,.95-.55,.22-1.31,.34-2.29,.34h-25.5c-1.3,0-2.31-.34-3.02-1.04-.71-.69-1.07-1.59-1.07-2.68,0-.94,.24-1.66,.73-2.17,.49-.51,1.28-1.03,2.38-1.56l1.4-.67v-24.83Z" />
            <path fill="currentColor" d="M182.7,85.15l-1.04-.49c-1.22-.57-2.1-1.18-2.65-1.83-.55-.65-.82-1.5-.82-2.56,0-.93,.33-1.71,.98-2.32,.65-.61,1.56-.92,2.75-.92h10.19c1.42,0,2.53,.2,3.32,.61,.79,.41,1.53,1.16,2.23,2.26l9.82,15.19c.65,1.02,1.27,2.03,1.86,3.05,.59,1.02,1.11,1.95,1.55,2.81,.53,1.02,1.04,1.99,1.53,2.93l.12-.06c-.12-1.14-.26-2.3-.43-3.48-.12-1.02-.23-2.09-.34-3.23-.1-1.14-.17-2.24-.21-3.29l-.24-8.66-.98-.49c-1.18-.57-2.06-1.18-2.65-1.83-.59-.65-.88-1.5-.88-2.56,0-.93,.33-1.71,.98-2.32,.65-.61,1.56-.92,2.75-.92h10.86c1.18,0,2.09,.3,2.75,.92,.65,.61,.98,1.38,.98,2.32,0,1.06-.24,1.9-.73,2.53s-1.34,1.27-2.56,1.92l-.79,.43v26.66c0,2.07-.62,3.69-1.86,4.85s-3.16,1.74-5.76,1.74c-1.91,0-3.47-.32-4.67-.98-1.2-.65-2.29-1.75-3.26-3.29l-9.21-14.21c-.69-1.02-1.33-2.04-1.92-3.08-.59-1.04-1.11-1.98-1.56-2.84-.53-1.02-1.02-1.97-1.46-2.87l-.12,.06c.12,1.14,.26,2.3,.43,3.48,.12,1.02,.23,2.1,.34,3.26,.1,1.16,.17,2.25,.21,3.26l.24,8.78,1.34,.67c1.06,.53,1.85,1.05,2.38,1.56,.53,.51,.79,1.23,.79,2.17,0,1.1-.36,1.99-1.07,2.68-.71,.69-1.72,1.04-3.02,1.04h-10.55c-1.3,0-2.31-.34-3.02-1.04-.71-.69-1.07-1.59-1.07-2.68,0-.94,.24-1.66,.73-2.17,.49-.51,1.28-1.03,2.38-1.56l1.4-.67v-24.83Z" />
            <path fill="currentColor" d="M249.31,85.04c-.37-.08-.77-.18-1.22-.31-.41-.08-.86-.16-1.37-.25-.51-.08-1.09-.12-1.74-.12-1.71,0-2.88,.25-3.51,.75-.63,.5-.95,1.16-.95,2,0,1.1,.45,1.97,1.34,2.62,.89,.65,2.02,1.25,3.39,1.8,1.36,.55,2.84,1.13,4.42,1.74,1.59,.61,3.06,1.39,4.42,2.35,1.36,.96,2.49,2.18,3.39,3.66,.89,1.48,1.34,3.39,1.34,5.7,0,1.99-.37,3.82-1.1,5.49-.73,1.67-1.86,3.11-3.39,4.33s-3.46,2.18-5.79,2.87c-2.34,.69-5.11,1.04-8.33,1.04-2.81,0-5.06-.24-6.77-.73s-2.87-1.04-3.48-1.65c-.29-.28-.52-.57-.7-.85-.18-.28-.33-.61-.46-.98-.12-.37-.2-.79-.24-1.28-.04-.49-.06-1.1-.06-1.83v-1.92c0-.39,.02-.92,.06-1.62,.08-1.18,.43-2.03,1.04-2.56s1.4-.79,2.38-.79c1.87,0,3.17,.99,3.9,2.96l.98,2.53c.41,.16,.83,.31,1.28,.43,.41,.08,.89,.16,1.46,.25,.57,.08,1.22,.12,1.95,.12,1.83,0,3.1-.3,3.81-.9,.71-.6,1.07-1.4,1.07-2.39,0-.85-.26-1.59-.79-2.2-.53-.61-1.22-1.15-2.07-1.62-.85-.47-1.83-.92-2.93-1.34s-2.22-.89-3.36-1.4c-1.14-.51-2.26-1.08-3.35-1.71-1.1-.63-2.07-1.39-2.93-2.29-.85-.89-1.55-1.96-2.07-3.2-.53-1.24-.79-2.71-.79-4.42,0-1.83,.36-3.53,1.07-5.09,.71-1.56,1.81-2.93,3.29-4.09,1.48-1.16,3.36-2.06,5.64-2.71,2.28-.65,4.98-.98,8.11-.98,2.85,0,5.06,.23,6.65,.7,1.59,.47,2.66,.99,3.23,1.56,.28,.29,.52,.57,.7,.85,.18,.29,.34,.61,.46,.98,.12,.37,.2,.79,.24,1.28,.04,.49,.06,1.1,.06,1.83v1.83c0,.33-.02,.83-.06,1.52-.08,1.18-.43,2.03-1.04,2.56-.61,.53-1.4,.79-2.38,.79s-1.77-.25-2.38-.74c-.61-.49-1.12-1.23-1.52-2.22l-.92-2.34Z" />
            <path fill="currentColor" d="M266.39,85.15l-1.04-.49c-1.22-.57-2.1-1.18-2.65-1.83s-.82-1.5-.82-2.56c0-.93,.33-1.71,.98-2.32,.65-.61,1.57-.92,2.75-.92h24.83c.98,0,1.74,.11,2.29,.33,.55,.22,1.03,.54,1.43,.95,.41,.41,.67,.88,.79,1.4,.12,.53,.18,1.36,.18,2.5v2.04c0,.43-.02,.95-.06,1.56-.08,1.18-.42,2.03-1.01,2.56-.59,.53-1.41,.79-2.47,.79-.98,0-1.75-.21-2.32-.64-.57-.43-1.04-1.09-1.4-1.98l-.73-1.83h-7.75v9.46h5.31l.55-1.28c.28-.69,.69-1.18,1.22-1.46,.53-.28,1.14-.43,1.83-.43,.93,0,1.7,.27,2.29,.82,.59,.55,.88,1.35,.88,2.41v6.53c0,1.06-.3,1.86-.88,2.41-.59,.55-1.35,.82-2.29,.82-.69,0-1.3-.15-1.83-.46-.53-.3-.92-.74-1.16-1.31l-.61-1.4h-5.31v9.58h8.72l.85-2.32c.33-.89,.77-1.54,1.34-1.95,.57-.41,1.34-.61,2.32-.61,1.06,0,1.89,.27,2.5,.79,.61,.53,.93,1.38,.98,2.56,0,.49,0,.84,.03,1.07,.02,.22,.03,.43,.03,.61v2.29c0,1.14-.06,1.98-.18,2.53-.12,.55-.39,1.03-.79,1.43s-.88,.72-1.43,.95c-.55,.22-1.31,.34-2.29,.34h-25.5c-1.3,0-2.31-.34-3.02-1.04-.71-.69-1.07-1.59-1.07-2.68,0-.94,.24-1.66,.73-2.17,.49-.51,1.28-1.03,2.38-1.56l1.4-.67v-24.83Z" />
            <path fill="currentColor" d="M321.47,85.04c-.37-.08-.77-.18-1.22-.31-.41-.08-.86-.16-1.37-.25-.51-.08-1.09-.12-1.74-.12-1.71,0-2.88,.25-3.51,.75-.63,.5-.95,1.16-.95,2,0,1.1,.45,1.97,1.34,2.62,.89,.65,2.02,1.25,3.39,1.8,1.36,.55,2.84,1.13,4.42,1.74,1.59,.61,3.06,1.39,4.42,2.35,1.36,.96,2.49,2.18,3.39,3.66,.89,1.48,1.34,3.39,1.34,5.7,0,1.99-.37,3.82-1.1,5.49-.73,1.67-1.86,3.11-3.39,4.33-1.53,1.22-3.46,2.18-5.79,2.87-2.34,.69-5.12,1.04-8.33,1.04-2.81,0-5.06-.24-6.77-.73s-2.87-1.04-3.48-1.65c-.28-.28-.52-.57-.7-.85-.18-.28-.33-.61-.46-.98-.12-.37-.2-.79-.24-1.28-.04-.49-.06-1.1-.06-1.83v-1.92c0-.39,.02-.92,.06-1.62,.08-1.18,.43-2.03,1.04-2.56,.61-.53,1.4-.79,2.38-.79,1.87,0,3.17,.99,3.9,2.96l.98,2.53c.41,.16,.83,.31,1.28,.43,.41,.08,.89,.16,1.46,.25,.57,.08,1.22,.12,1.95,.12,1.83,0,3.1-.3,3.81-.9,.71-.6,1.07-1.4,1.07-2.39,0-.85-.27-1.59-.79-2.2-.53-.61-1.22-1.15-2.07-1.62-.85-.47-1.83-.92-2.93-1.34-1.1-.43-2.22-.89-3.36-1.4-1.14-.51-2.26-1.08-3.35-1.71-1.1-.63-2.07-1.39-2.93-2.29-.85-.89-1.55-1.96-2.07-3.2-.53-1.24-.79-2.71-.79-4.42,0-1.83,.36-3.53,1.07-5.09,.71-1.56,1.81-2.93,3.29-4.09,1.48-1.16,3.36-2.06,5.64-2.71,2.28-.65,4.98-.98,8.11-.98,2.85,0,5.06,.23,6.65,.7,1.59,.47,2.66,.99,3.23,1.56,.28,.29,.52,.57,.7,.85,.18,.29,.33,.61,.46,.98s.2,.79,.24,1.28c.04,.49,.06,1.1,.06,1.83v1.83c0,.33-.02,.83-.06,1.52-.08,1.18-.43,2.03-1.04,2.56-.61,.53-1.4,.79-2.38,.79s-1.77-.25-2.38-.74-1.12-1.23-1.53-2.22l-.92-2.34Z" />
            <path fill="currentColor" d="M227.14,24.4c-.08,0-.2-.02-.37-.06-.12-.04-.27-.07-.46-.09-.18-.02-.4-.03-.64-.03-1.18,0-2.3,.24-3.35,.73-1.06,.49-1.98,1.23-2.78,2.23-.79,1-1.42,2.26-1.89,3.78-.47,1.53-.7,3.32-.7,5.4s.23,3.79,.7,5.28c.47,1.49,1.14,2.74,2.01,3.75,.87,1.02,1.92,1.77,3.14,2.26,1.22,.49,2.56,.73,4.03,.73,.81,0,1.53-.04,2.14-.12,.61-.08,1.16-.18,1.65-.31s.93-.22,1.34-.3c.41-.08,.79-.12,1.16-.12,.98,0,1.73,.29,2.26,.85s.79,1.34,.79,2.32c0,.73-.12,1.37-.37,1.92-.24,.55-.61,1.07-1.1,1.56-.41,.41-.95,.81-1.62,1.22-.67,.41-1.5,.78-2.47,1.13s-2.07,.63-3.29,.85c-1.22,.22-2.58,.34-4.09,.34-3.13,0-5.94-.45-8.42-1.34-2.48-.89-4.58-2.19-6.28-3.87s-3.01-3.74-3.9-6.16c-.9-2.42-1.34-5.15-1.34-8.2,0-3.38,.55-6.46,1.65-9.24,1.1-2.78,2.65-5.16,4.67-7.14,2.01-1.97,4.44-3.51,7.29-4.61,2.85-1.1,6.04-1.65,9.58-1.65,2.2,0,3.94,.2,5.25,.61,1.3,.41,2.26,.92,2.87,1.53,.28,.29,.52,.57,.7,.85,.18,.29,.33,.63,.46,1.04,.12,.41,.2,.88,.24,1.43,.04,.55,.06,1.23,.06,2.04v2.96c0,.63-.02,1.52-.06,2.65-.04,1.18-.36,2.03-.95,2.56-.59,.53-1.37,.79-2.35,.79-1.06,0-1.87-.23-2.44-.7-.57-.47-1.06-1.25-1.46-2.35l-1.65-4.51Z" />
            <path fill="currentColor" d="M242.39,19.58c0-.98,.18-1.89,.55-2.75,.37-.85,.86-1.6,1.5-2.23s1.37-1.13,2.23-1.5c.85-.37,1.77-.55,2.75-.55,1.95,0,3.59,.71,4.91,2.14,1.32,1.42,1.98,3.35,1.98,5.79,0,1.67-.24,3.29-.73,4.88-.49,1.59-1.12,3.06-1.89,4.42-.77,1.36-1.63,2.57-2.56,3.63-.94,1.06-1.85,1.85-2.75,2.38-.65,.41-1.29,.72-1.92,.95-.63,.22-1.33,.32-2.1,.27-.77-.04-1.39-.27-1.86-.7-.47-.43-.7-1.05-.7-1.86,0-.61,.16-1.15,.49-1.62,.33-.47,.71-.99,1.16-1.56,.45-.57,.94-1.28,1.46-2.14,.53-.85,.98-1.97,1.34-3.35-.85-.37-1.71-1.07-2.56-2.1-.85-1.04-1.28-2.41-1.28-4.12Z" />
            <path fill="currentColor" d="M260.87,37.76c0-3.42,.53-6.5,1.59-9.24,1.06-2.75,2.57-5.09,4.54-7.05,1.97-1.95,4.35-3.46,7.14-4.51,2.79-1.06,5.91-1.59,9.36-1.59,3.17,0,6.03,.47,8.57,1.4,2.54,.94,4.69,2.27,6.43,4,1.75,1.73,3.09,3.84,4.03,6.34s1.4,5.32,1.4,8.45c0,2.89-.39,5.5-1.16,7.84-.77,2.34-1.83,4.39-3.17,6.16-1.34,1.77-2.92,3.25-4.73,4.45-1.81,1.2-3.75,2.08-5.83,2.65,1.42,1.63,3.38,2.87,5.89,3.72,2.5,.85,5.54,1.4,9.12,1.65,1.06,.08,1.84,.43,2.35,1.04,.51,.61,.76,1.34,.76,2.2,0,.73-.2,1.47-.61,2.23-.41,.75-1.04,1.41-1.89,1.98-.85,.57-1.95,1.04-3.29,1.4s-2.93,.55-4.76,.55-3.58-.24-5.37-.73c-1.79-.49-3.45-1.28-4.97-2.38-1.52-1.1-2.89-2.52-4.09-4.27-1.2-1.75-2.12-3.88-2.78-6.41-2.77-.12-5.29-.66-7.56-1.62-2.28-.96-4.23-2.29-5.86-4-1.63-1.71-2.89-3.76-3.78-6.16s-1.34-5.1-1.34-8.11Zm13.42-1.22c0,2.16,.22,4.03,.67,5.61s1.05,2.89,1.8,3.9c.75,1.02,1.63,1.77,2.62,2.26,1,.49,2.04,.73,3.14,.73s2.13-.23,3.11-.7c.98-.47,1.82-1.2,2.53-2.2,.71-1,1.28-2.27,1.71-3.81,.43-1.54,.64-3.4,.64-5.55s-.22-3.94-.67-5.49c-.45-1.54-1.05-2.83-1.8-3.84-.75-1.02-1.63-1.77-2.62-2.26-1-.49-2.04-.73-3.14-.73s-2.13,.23-3.11,.7c-.98,.47-1.82,1.19-2.53,2.17-.71,.98-1.28,2.23-1.71,3.75-.43,1.53-.64,3.35-.64,5.46Z" />
            <path fill="currentColor" d="M310.04,24.16l-.55-.37c-1.1-.73-1.91-1.4-2.44-2.01-.53-.61-.79-1.44-.79-2.5,0-.93,.33-1.71,.98-2.32,.65-.61,1.57-.92,2.75-.92h13.3c1.18,0,2.08,.3,2.71,.92,.63,.61,.95,1.38,.95,2.32,0,1.06-.23,1.89-.7,2.5-.47,.61-1.29,1.26-2.47,1.95l-.73,.43v15.86c0,3.25,.59,5.59,1.77,7.01,1.18,1.42,2.72,2.14,4.64,2.14s3.42-.62,4.51-1.86c1.1-1.24,1.65-3.26,1.65-6.07V24.16l-.73-.43c-1.18-.69-2.01-1.34-2.5-1.95-.49-.61-.73-1.44-.73-2.5,0-.93,.33-1.71,.98-2.32,.65-.61,1.57-.92,2.75-.92h11.16c1.18,0,2.09,.3,2.75,.92,.65,.61,.98,1.38,.98,2.32,0,1.06-.27,1.89-.79,2.5-.53,.61-1.34,1.28-2.44,2.01l-.55,.37v16.1c0,5.94-1.47,10.34-4.42,13.21-2.95,2.87-7.59,4.3-13.94,4.3s-10.79-1.38-13.69-4.15c-2.91-2.77-4.36-6.81-4.36-12.14V24.16Z" />
            <path fill="currentColor" d="M356.09,24.16l-1.04-.49c-1.22-.57-2.1-1.18-2.65-1.83s-.82-1.5-.82-2.56c0-.93,.33-1.71,.98-2.32,.65-.61,1.57-.92,2.75-.92h24.83c.98,0,1.74,.11,2.29,.33,.55,.22,1.03,.54,1.43,.95,.41,.41,.67,.88,.79,1.4,.12,.53,.18,1.36,.18,2.5v2.04c0,.43-.02,.95-.06,1.56-.08,1.18-.42,2.03-1.01,2.56-.59,.53-1.41,.79-2.47,.79-.98,0-1.75-.21-2.32-.64-.57-.43-1.04-1.09-1.4-1.98l-.73-1.83h-7.75v9.46h5.31l.55-1.28c.28-.69,.69-1.18,1.22-1.46,.53-.28,1.14-.43,1.83-.43,.93,0,1.7,.27,2.29,.82,.59,.55,.88,1.35,.88,2.41v6.53c0,1.06-.3,1.86-.88,2.41-.59,.55-1.35,.82-2.29,.82-.69,0-1.3-.15-1.83-.46-.53-.3-.92-.74-1.16-1.31l-.61-1.4h-5.31v9.58h8.72l.85-2.32c.33-.89,.77-1.54,1.34-1.95,.57-.41,1.34-.61,2.32-.61,1.06,0,1.89,.27,2.5,.79,.61,.53,.93,1.38,.98,2.56,0,.49,0,.84,.03,1.07,.02,.22,.03,.43,.03,.61v2.29c0,1.14-.06,1.98-.18,2.53-.12,.55-.39,1.03-.79,1.43s-.88,.72-1.43,.95c-.55,.22-1.31,.34-2.29,.34h-25.5c-1.3,0-2.31-.34-3.02-1.04-.71-.69-1.07-1.59-1.07-2.68,0-.94,.24-1.66,.73-2.17,.49-.51,1.28-1.03,2.38-1.56l1.4-.67V24.16Z" />
            <path fill="currentColor" d="M165.19,22.57c-3.7,0-6.62,3.26-6.62,6.76,0,.71,.09,1.4,.28,2.12,1.17-.55,2.25-.8,3.5-.8,2.97,0,4.9,1.45,7.36,4.07,1.47-1.47,2.23-3.33,2.23-5.33,0-3.63-2.94-6.81-6.74-6.81Z" />
            <path fill="currentColor" d="M162.18,32.18c-.92,0-1.79,.25-2.78,.71,1.26,2.3,3.54,3.59,5.91,3.59,1.03,0,2.02-.28,3.08-.81-2.14-2.23-3.59-3.5-6.21-3.5Z" />
            <path fill="currentColor" d="M180.64,24.37c-.82-1.88-2.01-3.58-3.49-5-3.07-2.97-7.18-4.62-11.45-4.6-4.27-.02-8.38,1.63-11.45,4.6-1.48,1.42-2.66,3.12-3.49,5-.85,1.94-1.29,4.03-1.28,6.15,0,5.21,1.6,7.42,8.36,16.73,1.47,2.03,3.14,4.34,5.13,7.12,.63,.88,1.65,1.4,2.73,1.4,1.08,0,2.1-.52,2.73-1.4,1.98-2.78,3.65-5.09,5.13-7.12,6.75-9.31,8.36-11.53,8.36-16.73,0-2.12-.43-4.21-1.28-6.15Zm-23.75,5.03c0-2.28,.9-4.39,2.55-6.02,1.59-1.56,3.52-2.37,5.77-2.37,4.69,0,8.37,3.52,8.37,8.67,0,2.41-.85,4.37-2.64,6.05,.74,.48,1.56,.64,2.62,.64h.21v1.43c-.32,.02-.6,.05-.81,.05-.99,0-2.21-.39-3.29-1.15-1.33,.87-2.83,1.31-4.46,1.31-4.62,0-8.32-3.68-8.32-8.6Z" />
        </svg>
    );
}

LogoIcon.propTypes = propTypes;
LogoIcon.defaultProps = defaultProps;

export default LogoIcon;
