/* eslint-disable jsx-a11y/control-has-associated-label, react/no-danger, react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';

import Postcards from './Postcards';
import ResultPins from './ResultPins';

import styles from '../../styles/partials/postcards.module.scss';

const propTypes = {
    game: PropTypes.shape({}),
    steps: PropTypes.arrayOf(PropTypes.shape({})),
    currentScore: PropTypes.number,
    className: PropTypes.string,
    hasFoundAnswer: PropTypes.bool,
};

const defaultProps = {
    game: null,
    steps: [],
    currentScore: 1,
    className: null,
    hasFoundAnswer: false,
};

const GuessThePark = ({ game, steps, currentScore, className, hasFoundAnswer }) => {
    const {id = null, title = null} = game || {};

    const [selectedPhoto, setSelectedPhoto] = useState(null);

    const onClickPin = useCallback(
        (step) => {
            setSelectedPhoto(step);
            gtag('event', 'click_photo_pin', {
                game: { id, title },
                'index': step
            });
        },
        [setSelectedPhoto],
    );

    useEffect(() => {
        setSelectedPhoto(null);
    }, [currentScore]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            {steps !== null ? (
                <Postcards
                    currentStep={currentScore}
                    currentPhoto={selectedPhoto}
                    images={steps}
                />
            ) : null}
            <ResultPins
                currentStep={currentScore}
                hasFoundAnswer={hasFoundAnswer}
                onClickPin={onClickPin}
            />
        </div>
    );
};

GuessThePark.propTypes = propTypes;
GuessThePark.defaultProps = defaultProps;

export default GuessThePark;
