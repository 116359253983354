/* eslint-disable jsx-a11y/control-has-associated-label, react/no-danger, react/jsx-props-no-spreading */
import { useSpring } from '@react-spring/core';
import { animated as a } from '@react-spring/web';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/partials/postcards.module.scss';

const propTypes = {
    images: AppPropTypes.images.isRequired,
    currentStep: PropTypes.number,
    currentPhoto: PropTypes.number,
    className: PropTypes.string,
};

const defaultProps = {
    currentStep: 1,
    currentPhoto: null,
    className: null,
};

const Postcards = ({ images, currentStep, currentPhoto, className }) => {
    const [photoSpring, setPhotoSpring] = useSpring(() => ({
        x: '0%',
        config: { tension: 300, friction: 30 },
    }));

    useEffect(() => {
        const ended = currentStep > 4;
        const current = ended ? 4 : currentStep;
        const step = currentPhoto !== null ? currentPhoto : current;

        setPhotoSpring.start({
            x: `${-100 * (step - 1)}%`,
        });
    }, [currentStep, currentPhoto, setPhotoSpring]);


    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.frame}>
                <a.div className={styles.slides} style={photoSpring}>
                    {images.map((image, index) => {
                        const step = index + 1;
                        const { id, url, alt = null, credits = null } = image || {};
                        const ended = currentStep > 4;

                        if (step > currentStep && !ended) return false; // don't reveal all the images in the DOM

                        return (
                            <div key={`slide-${id}-${index + 1}`} className={styles.slide}>
                                <img className={styles.image} src={url} alt={alt} />
                                {credits !== null ? (
                                    <div className={styles.credits}>
                                        Crédits: {credits}
                                    </div>
                                ): null}
                            </div>
                        );
                    })}
                </a.div>
            </div>
        </div>
    );
};

Postcards.propTypes = propTypes;
Postcards.defaultProps = defaultProps;

export default Postcards;
