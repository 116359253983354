import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function ArrowIcon({ className }) {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
        >
            <path
                d="M9.92,5.38c.1-.24,.1-.52,0-.76-.05-.12-.12-.23-.22-.33L5.71,.29c-.39-.39-1.02-.39-1.41,0s-.39,1.02,0,1.41l2.29,2.29H1c-.55,0-1,.45-1,1s.45,1,1,1H6.59l-2.29,2.29c-.39,.39-.39,1.02,0,1.41,.2,.2,.45,.29,.71,.29s.51-.1,.71-.29l4-4c.09-.09,.17-.2,.22-.33Z"
                fill="currentColor"
            />
        </svg>
    );
}

ArrowIcon.propTypes = propTypes;
ArrowIcon.defaultProps = defaultProps;

export default ArrowIcon;
