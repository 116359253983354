import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';

import { RoutesProvider } from './context/RoutesContext';
import { routes as allRoutes } from '../lib/data';

const defaultRichTextElements = {
    strong: (chunk) => <strong>{chunk}</strong>,
};

const propTypes = {
    children: PropTypes.node,
};

const defaultProps = {
    children: null,
};

function IntlRouter({ children }) {
    const locale = 'fr';

    const [messages, setMessages] = useState(null);
    useEffect(() => {
        import(`../lang/${locale}.json`).then(({ default: newMessages }) =>
            setMessages(newMessages),
        );
    }, [locale]);

    return messages !== null ? (
        <IntlProvider
            locale={locale}
            messages={messages}
            defaultRichTextElements={defaultRichTextElements}
        >
            <RoutesProvider routes={allRoutes.default}>{children}</RoutesProvider>
        </IntlProvider>
    ) : null;
}

IntlRouter.propTypes = propTypes;
IntlRouter.defaultProps = defaultProps;

export default IntlRouter;
